<template lang="pug">
om-modal.delete-modal(
  :name="modalName"
  width="600"
  color="light"
  @closeClicked="closeModal"
  @beforeOpen="beforeOpenModal"
)
  template(#header-title)
    h3 {{ $t('feeds.delete.modal.title') }}

  template(#modal-body)
    span {{ $t('feeds.delete.modal.description') }}

  template(#modal-footer)
    .d-flex.justify-content-end.buttons-wrapper
      om-button.cancel-btn(secondary @click="closeModal") {{ $t('cancel') }}
      om-button.ml-2.confirm-btn(primary @click="deleteFeed" :loading="confirmed") {{ $t('sure') }}
</template>
<script>
  import DELETE_DATA_SOURCE from '@/graphql/DeleteDataSource.gql';

  export default {
    data: () => ({
      modalName: 'feed-delete-modal',
      id: null,
      confirmed: false,
    }),
    methods: {
      async deleteFeed() {
        this.confirmed = true;
        try {
          const response = await this.$apollo.mutate({
            mutation: DELETE_DATA_SOURCE,
            variables: {
              id: this.id,
            },
          });

          const success = response?.data?.result?.success ?? false;

          if (success) {
            this.$notify({
              type: 'success',
              text: this.$t('feeds.delete.success'),
            });
          } else {
            this.$notify({
              type: 'error',
              text: this.$t('feeds.delete.failed'),
            });
          }
        } catch (err) {
          console.error('Caugth error during delete feed', { msg: err.message, stack: err.stack });
        } finally {
          this.$bus.$emit('reloadFeedTable');
          this.closeModal();
        }
      },
      closeModal() {
        this.id = null;
        this.confirmed = false;
        this.$modal.hide(this.modalName);
      },
      beforeOpenModal({ params: { id } }) {
        this.id = id;
      },
    },
  };
</script>
